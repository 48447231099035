<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Usuários Cadatrados</h4>
        <el-col :md="12">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openUserModal"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="users"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column prop="username" label="usuário"> </el-table-column>
      <el-table-column prop="group.name" label="grupo"> </el-table-column>
      <el-table-column prop="last_access" label="último acesso">
      </el-table-column>
      <el-table-column label="ações">
        <template #default="user">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                @confirm="authorizeLogin(user.row)"
                v-if="hasPendingAuth(user.row.pending_auth)"
                title="Permitir acesso do usuário?"
              >
                <template #reference>
                  <el-button
                    onlyIcon
                    type="primary"
                    icon="el-icon-question"
                    circle
                  ></el-button>
                </template>
              </el-popconfirm>
              <el-button
                v-else
                onlyIcon
                type="success"
                icon="el-icon-check"
                circle
              ></el-button>

              <el-popconfirm
                @confirm="changeUserStatus(user.row, !user.row.is_active)"
                :title="
                  user.row.is_active
                    ? 'Bloquear usuário?'
                    : 'Desbloquear usuário?'
                "
              >
                <template #reference>
                  <el-button
                    onlyIcon
                    :type="user.row.is_active ? 'primary' : 'danger'"
                    circle
                    ><i
                      :class="{
                        'el-icon-lock': !user.row.is_active,
                        'el-icon-unlock': user.row.is_active,
                      }"
                    ></i
                  ></el-button>
                </template>
              </el-popconfirm>
              <el-popconfirm
                @confirm="redefinePassword(user.row)"
                title="
              Redefinir senha do usuário?
            "
                ><template #reference>
                  <el-button onlyIcon type="primary" circle>
                    <i class="el-icon-refresh"></i></el-button></template
              ></el-popconfirm>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openUserModal(user.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteUser(user.row)"
                title="
              Remover usuário?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <user-modal
      :showModal="showUserModal"
      :user="user"
      @close-modal="showUserModal = false"
      @should-update="fetchUsers"
    ></user-modal>
  </el-card>
</template>

<script>
import UserModal from "./modals/User.vue";
export default {
  name: "Users",
  components: { UserModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    users: null,
    user: null,
    showUserModal: false,
  }),
  mounted() {
    this.fetchUsers();
  },
  methods: {
    deleteUser(user) {
      fetch(`${this.$store.state.apiUrl}users/${user.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.text();
        })
        .then(() => {
          this.fetchUsers();
          this.$notify({
            title: "Usuário removido com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch(() => {
          this.$notify({
            title: "Ocorreu um erro",
            type: "error",
            message:
              "Não foi possível remover o usuário, talvez você não tenha permissões suficientes",
            position: "bottom-right",
          });
        });
    },
    hasPendingAuth(pending_auth) {
      if (Array.isArray(pending_auth)) return pending_auth.length > 0;
      return false;
    },
    openUserModal(user) {
      this.user = user;
      this.showUserModal = true;
    },
    async redefinePassword(user) {
      this.updatePassword({
        username: user?.username,
      })
        ?.then((r) => {
          if (r.status === 200) return r.json();
          else throw r.json();
        })
        ?.then(
          () =>
            this.$notify({
              title: "A senha foi redefinida",
              type: "success",
              message: "O usuário receberá no email a nova senha",
              position: "bottom-right",
            }),
          () =>
            this.$notify({
              title: "Não foi possível redefinir a senha.",
              type: "error",
              message: "Tente novamente",
              position: "bottom-right",
            })
        );
    },
    updatePassword(user) {
      if (user) {
        return fetch(`${this.$store.state.apiUrl}users/password/redefine`, {
          credentials: "include",
          method: "POST",
          body: JSON.stringify(user),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
      }
    },
    changeUserStatus(user, status) {
      fetch(`${this.$store.state.apiUrl}users/${user.uid}`, {
        credentials: "include",
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          is_active: status ? "1" : "0",
        }),
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.text();
        })
        .then(() => {
          this.fetchUsers();
          this.$notify({
            title: status ? "Usuário desbloqueado" : "Usuário bloqueado",
            type: "success",
            message: status
              ? "Agora o usuário pode acessar o sistema"
              : "O usuário não poderá mais utilizar o sistema",
            position: "bottom-right",
          });
        })
        .catch(() => {
          this.$notify({
            title: "Ocorreu um erro",
            type: "error",
            message: "Não foi possível autorizar a entrada",
            position: "bottom-right",
          });
        });
    },
    authorizeLogin(user) {
      fetch(`${this.$store.state.apiUrl}users/${user.uid}/authorize`, {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.text();
        })
        .then(() => {
          this.fetchUsers();
          this.$notify({
            title: "Login autorizado.",
            type: "success",
            message:
              "Informe o usuário que ele pode começar a utilizar o sistema",
            position: "bottom-right",
          });
        })
        .catch(() => {
          this.$notify({
            title: "Ocorreu um erro",
            type: "error",
            message: "Não foi possível autorizar a entrada",
            position: "bottom-right",
          });
        });
    },
    fetchUsers() {
      fetch(`${this.$store.state.apiUrl}users`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.users = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>