<template>
  <el-dialog :title="user_.name" v-model="shouldShow">
    <form>
      <base-input
        label="Nome:"
        hint="Insira o nome completo"
        v-model="user_.name"
      >
      </base-input>
      <base-input
        label="Usuário:"
        hint="Usuário utilizado para efetuar o login"
        v-model="user_.username"
      ></base-input>
      <base-input
        label="Senha:"
        hint="O usuário deverá alterar a senha no primeiro acesso"
        v-model="user_.password"
      >
      </base-input>
      <base-input label="Email:" hint="Email do usuário" v-model="user_.email">
      </base-input>
      <el-select
        @change="user_.group_id = $event"
        clearable
        v-model="user_.group_id"
        size="medium"
      >
        <template #prefix>Grupo:</template>
        <el-option
          v-for="item in Groups"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false">
          Cancelar
        </el-button>
        <el-button type="text" class="button" @click="saveUser">
          Salvar
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["user", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      user_: {},
      groups: null,
      isLoadingSave: false,
    };
  },
  watch: {
    user(v) {
      this.user_ = v || {};
      if (v?.group) this.user_.group_id = v.group?.uid;
    },
  },
  computed: {
    Groups() {
      return this.groups || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  mounted() {
    this.fetchGroups();
  },
  methods: {
    saveUser() {
      const passwordUpdated = this.user_.password !== this.user.password;
      const userExists = "uid" in this.user_;

      if (!passwordUpdated)
        this.user_["password_confirmation"] = this.user_["password"];

      this.isLoadingSave = true;
      fetch(
        `${this.$store.state.apiUrl}users${
          userExists ? `/${this.user_.uid}` : ""
        }`,
        {
          credentials: "include",
          method: userExists ? "PUT" : "POST",
          body: JSON.stringify(this.user_),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          () => {
            this.$emit("should-update");
            ElNotification.success({
              title: `Usuário ${
                userExists ? "alterado" : "cadastrado"
              } com sucesso!`,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Erro ao salvar a operação",
              message: Array.isArray(e) ? e[0].message : e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
    fetchGroups() {
      fetch(`${this.$store.state.apiUrl}groups`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status == 200) return response.json();
          throw response.json();
        })
        .then((json) => (this.groups = json))
        .catch(() => (this.groups = []));
    },
  },
  name: "UserModal",
};
</script>
<style scoped>
.el-autocomplete {
  margin-top: 5px !important;
  display: block !important;
}
</style>